import React, { useState } from "react";
import useOtpHook from "../Hooks/useOtpHook";
import { Button, Form, Modal, Select } from "antd";
import { MemberProfile, remitterKyc, verifyOtp } from "../ApiServices/Apifun";
import { useNavigate } from "react-router-dom";
import {
  deviceOptions,
  dispatchtoast,
  getLocalStorageItem,
  handleSubmitForAeps,
  mantrairisPIDOPTS,
  parseXml,
  startekPIDOPTS,
} from "../Utils";
import { useDispatch } from "react-redux";
import { setParentProfile, setProfile } from "../Store/B2bslice";
import { xml2json } from "../Utils/xml2json";
import axios from "axios";

const VerifyModal = ({
  isOpen,
  setIsOpen,
  otherFields,
  endpoint,
  from = "other",
  apicall = () => {},
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [btnLoading, setBtnLoading] = useState(false);
  const [referenceKey, setReferenceKey] = useState(null);
  const [showKycField, setShowKycField] = useState(false);
  const { handleChange, handleKeyUp, pin, inputRefs } = useOtpHook(6);

  const handleVerify = () => {
    setBtnLoading(true);
    const formdata = new FormData();
    formdata.append("otp", pin.join(""));
    if (otherFields) {
      Object.entries(otherFields).map(([key, value]) =>
        formdata.append(key, value)
      );
    }
    verifyOtp(formdata, "", endpoint)
      .then((res) => {
        if (res.status) {
          if (from === "register") {
            setReferenceKey(res.data.reference_key);
            setShowKycField(true);
          } else {
            setIsOpen(false);
            apicall();
            dispatchtoast(res.message);
            navigate("/dmt/remitters", { state: { allow: true } });
            MemberProfile()
              .then((res) => {
                if (res.status) {
                  dispatch(setProfile(res.data.profile));
                  dispatch(setParentProfile(res.data.parent_detail));
                } else {
                  dispatchtoast("Unable to fetch profile detail", true);
                }
              })
              .catch((err) => console.log(err, "errr"));
          }
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setBtnLoading(false));
  };
  const capture = (val, port = 11100) => {
    setBtnLoading(true);
    axios({
      method: "CAPTURE", // Set the custom request method here
      url:
        val.device === "morpho"
          ? `https://localhost:${port}/capture`
          : val.device === "startek"
          ? "https://localhost:11200/rd/capture"
          : `https://127.0.0.1:${port}/rd/capture`,
      data:
        val.device === "morpho"
          ? '<PidOptions ver="1.0">' +
            '<Opts fCount="1" fType="2" iCount="" iType="" pCount="" pType="" format="0" pidVer="2.0" timeout="10000" otp="" wadh="E0jzJ/P8UopUHAieZn8CKqS4WPMi5ZSYXgfnlfkWjrc=" posh=""/>' +
            "</PidOptions>"
          : val.device === "mantra"
          ? '<?xml version="1.0"?> <PidOptions ver="1.0"> <Opts fCount="1" fType="2" iCount="0" pCount="0" format="0" pidVer="2.0" timeout="20000" posh="UNKNOWN" env="P" wadh="E0jzJ/P8UopUHAieZn8CKqS4WPMi5ZSYXgfnlfkWjrc="/> <CustOpts><Param name="mantrakey" value="" /></CustOpts> </PidOptions>'
          : val.device === "startek"
          ? startekPIDOPTS
          : mantrairisPIDOPTS,
      headers: {
        "Content-Type": "text/xml",
        Accept: "text/xml",
      },
    })
      .then((response) => {
        const parsed = parseXml(response.data);
        const xmltojson = JSON.parse(xml2json(parsed).replace("undefined", ""));
        if (xmltojson.PidData.Resp.errCode !== "0") {
          dispatchtoast(
            `Failed to fetch fingerprint because of ${xmltojson.PidData.Resp.errInfo}`,
            true
          );
          setBtnLoading(false);
        } else {
          dispatchtoast("Fingerprint Captured SuccessFully");
          const parsed = parseXml(response.data);
          const xmltojson = JSON.parse(
            xml2json(parsed).replace("undefined", "")
          );

          const { dpId, rdsId, rdsVer, mi, mc, dc } =
            xmltojson.PidData.DeviceInfo;
          const { ci } = xmltojson.PidData.Skey;
          const { Hmac } = xmltojson.PidData;
          const payload = {
            reference_key: referenceKey,
            mobileNumber: otherFields?.mobile,
            latitude: getLocalStorageItem("latitude"),
            longitude: getLocalStorageItem("longitude"),
            srno: xmltojson?.PidData?.additional_info
              ? Array.isArray(xmltojson.PidData?.additional_info?.Param)
                ? xmltojson.PidData?.additional_info?.Param[0]?.value
                : xmltojson?.PidData?.additional_info?.Param.value
              : Array.isArray(
                  xmltojson?.PidData.DeviceInfo.additional_info?.Param
                )
              ? xmltojson?.PidData?.DeviceInfo?.additional_info?.Param[0]?.value
              : xmltojson?.PidData?.DeviceInfo?.additional_info?.Param?.value,
            dpId: dpId ? dpId : 0,
            rdsId: rdsId ? rdsId : 0,
            rdsVer: rdsVer ? rdsVer : 0,
            dc: dc ? dc : 0,
            mi: mi ? mi : 0,
            mc: mc ? mc : 0,
            ci: ci ? ci : 0,
            ts: "",
            skey: xmltojson.PidData.Skey["#text"],
            hmac: Hmac ? Hmac : 0,
            pidData: xmltojson.PidData.Data["#text"],
          };
          const formdata = new FormData();
          Object.entries(payload).forEach(([key, val]) =>
            formdata.append(key, val)
          );
          remitterKyc(formdata)
            .then((res) => {
              if (res.status) {
                setIsOpen(false);
                apicall();
                dispatchtoast(res.message);
                navigate("/dmt/remitters", { state: { allow: true } });
                MemberProfile()
                  .then((res) => {
                    if (res.status) {
                      dispatch(setProfile(res.data.profile));
                      dispatch(setParentProfile(res.data.parent_detail));
                    } else {
                      dispatchtoast("Unable to fetch profile detail", true);
                    }
                  })
                  .catch((err) => console.log(err, "errr"));
              } else {
                dispatchtoast(res.message ?? res.data.message, true);
              }
            })
            .catch((err) => {
              console.log(err);
            })
            .finally(() => setBtnLoading(false));
        }
      })
      .catch((error) => {
        setBtnLoading(false);
      });
  };
  return (
    <Modal
      title={showKycField?"Remitter Kyc":"Verify OTP"}
      footer={null}
      className="shadow-normal"
      width={300}
      maskClosable={false}
      open={isOpen}
      onCancel={() => setIsOpen(false)}
    >
      <div className="  rounded-3xl shadow-normal bg-white ">
        {!showKycField ? (
          <>
            <div className="header flex flex-col justify-center items-center">
              <p className="text-[15px] mt-3 text-gray-500">Enter OTP</p>
            </div>
            <div className=" mt-5 flex justify-center items-center">
              {[1, 2, 3, 4, 5, 6].map((i, index) => {
                return (
                  <>
                    <input
                      key={index}
                      ref={inputRefs[index]}
                      type="text"
                      maxLength={1}
                      className="w-[15px] otpinput xs:w-[35px] sm:w-[35px] md:w-[40px] text-bsblackrgb rounded-md bg-gray-300 h-8  mx-1 border-none focus:outline-none focus:border-none text-center"
                      value={pin[index]}
                      onChange={(e) => handleChange(index, e.target.value)}
                      onKeyUp={(e) => handleKeyUp(index, e)}
                    />
                  </>
                );
              })}
            </div>
            <Button
              loading={btnLoading}
              onClick={() => pin.length === 6 && handleVerify()}
              className={`SignInBtn w-full ${
                pin.length === 6 ? "cursor-pointer" : "cursor-not-allowed"
              }  mt-4 ${
                pin.length === 6 ? "bg-primary" : "bg-bsgray"
              }  flex justify-center border-none items-center h-12 mt-10 rounded-full text-white text-[12px] font-bold`}
            >
              <p>Verify OTP</p>
            </Button>
          </>
        ) : (
          <>
            <Form
              name="basic"
              initialValues={{
                remember: true,
              }}
              onFinishFailed={(err) => console.log(err)}
              onFinish={(val) => handleSubmitForAeps(val, capture,setBtnLoading)}
            >
              <Form.Item
                name={`device`}
                label={"Select Device"}
                labelCol={{ span: 24 }}
                rules={[
                  {
                    required: true,
                    message: "Select any one device!",
                  },
                ]}
              >
                <Select
                  placeholder="Select a device"
                  className="w-full mt-2"
                  options={deviceOptions}
                />
              </Form.Item>{" "}
              <div className="m-2">
                <Form.Item>
                  <Button
                    htmlType="submit"
                    loading={btnLoading}
                    className="bg-primary p-3 w-full flex justify-center items-center outline-none border-none  text-white  rounded-full mt-4"
                  >
                    Submit{" "}
                  </Button>
                </Form.Item>
              </div>
            </Form>
          </>
        )}
      </div>
    </Modal>
  );
};

export default VerifyModal;
