import React, { useEffect, useRef, useState } from "react";
import { Form, Select, Button, DatePicker, Radio, Input, Upload } from "antd";
import {
  aadharPattern,
  panPattern,
  pincodePattern,
  mobilePattern,
  emailPattern,
  filterOption,
  dispatchtoast,
  PortalName,
} from "../../Utils";
import {
  getCityAuthless,
  getCountryAuthless,
  getStateAuthLess,
  getmemberPackages,
  getmemberTypeAuthless,
  userRegister,
} from "../../ApiServices/Apifun";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import CommonInput from "../../Common/CommonInput";
import { Images } from "../../Controller";
import { UploadOutlined } from "@ant-design/icons";
import Compressor from "compressorjs";
const Register = () => {
  const formRef = useRef();
  const [form] = Form.useForm();
  const [membercustomPayload, setCustomMemberPayload] = useState({});
  const [country, setCountry] = useState([]);
  const [check, setcheck] = useState(false);
  const [state, setState] = useState([]);
  const [city, setCity] = useState([]);
  const [packages, setPackages] = useState([]);
  //   const [selectedMember, setSelectedMember] = useState(null);
  const [memberType, setMemberType] = useState([]);
  const profiledetail = useSelector((state) => state.B2B.profileDetail);
  const dispatch = useDispatch();
  const [aadhar_front, setaadhar_front] = useState([]);
  const [aadhar_back, setaadhar_back] = useState([]);
  const [aadhar_front_back, setaadhar_front_back] = useState([]);
  const [pan_card, setpan_card] = useState([]);
  const [business_proof, setbusiness_proof] = useState([]);
  const [retailer_photo, setretailer_photo] = useState([]);
  const [retailer_distributor, setretailer_distributor] = useState([]);
  const [shop_photo, setshop_photo] = useState([]);
  const [bank_photo, setbank_photo] = useState([]);

  const [pan_cardbase, setpan_cardBase] = useState("");
  const [aadhar_frontbase, setaadhar_frontBase] = useState("");
  const [aadhar_backbase, setaadhar_backBase] = useState("");
  const [aadhar_front_backbase, setaadhar_front_backBase] = useState("");
  const [business_proofbase, setbusiness_proofBase] = useState("");
  const [retailer_photobase, setretailer_photoBase] = useState("");
  const [retailer_distributorbase, setretailer_distributorBase] = useState("");
  const [shop_photobase, setshop_photoBase] = useState("");
  const [bank_photobase, setbank_photoBase] = useState("");
  const navigate = useNavigate();
  useEffect(() => {
    // dispatch(setselectedTab("15"));
    // if (profiledetail.member_type === "Retailer") {
    //   navigate("/dashboard");
    // } else {
    getCountryAuthless()
      .then((res) => {
        setCountry(
          res.countries.map((i, index) => {
            return {
              label: i.name,
              value: i.id,
              key: i.id,
            };
          })
        );
      })
      .catch((err) => console.log(err));
    getmemberTypeAuthless()
      .then((res) => {
        setMemberType(
          res.data.map((i) => {
            return {
              label: i.type,
              value: i.id,
              key: i.id,
            };
          })
        );
      })
      .catch((err) => console.log(err));
    // }
  }, []);
  const props = {
    showUploadList: {
      showRemoveIcon: false,
    },
  };
  const handleImageChange = (file, from) => {
    try {
      new Compressor(file, {
        quality: 0.8, // 0.6 can also be used, but its not recommended to go below.
        mimeType: "image/jpeg",
        success: (compressedResult) => {
          const reader = new FileReader();
          reader.onload = () => {
            let data = { ...compressedResult };
            data.name = compressedResult.name;
            data.thumbUrl = URL.createObjectURL(compressedResult);



            if (from === "aadhar_front") {
              setaadhar_frontBase(reader.result);
              setaadhar_front([data]);
            } else if (from === "aadhar_back") {
              setaadhar_backBase(reader.result);
              setaadhar_back([data]);
            }
            else if (from === "aadhar_front_back") {
              setaadhar_front_backBase(reader.result);
              setaadhar_front_back([data]);
            }
            else if (from === "pan_card") {
              setpan_cardBase(reader.result);
              setpan_card([data]);
            }
            else if (from === "business_proof") {
              setbusiness_proofBase(reader.result);
              setbusiness_proof([data]);
            }
            else if (from === "retailer_photo") {
              setretailer_photoBase(reader.result);
              setretailer_photo([data]);
            }
            else if (from === "retailer_distributor") {
              setretailer_distributorBase(reader.result);
              setretailer_distributor([data]);
            }
            else if (from === "shop_photo") {
              setshop_photoBase(reader.result);
              setshop_photo([data]);
            }
            else {
              setbank_photoBase(reader.result);
              setbank_photo([data]);
            }

          };
          reader.readAsDataURL(compressedResult);
        },
      });
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };
  // const handleGetPackage = (val) => {
  //   setSelectedMember(val);
  //   setCustomMemberPayload({});
  //   getmemberPackages({ member_type: val })
  //     .then((res) => setPackages(res.packages))
  //     .catch((err) => console.log(err));
  // };
  const handleGetState = (val) => {
    const payload = {
      country_id: val,
    };
    getStateAuthLess(payload)
      .then((res) => {
        setState(
          res.states.map((i, index) => {
            return {
              label: i.name,
              value: i.id,
              key: i.id,
            };
          })
        );
      })
      .catch((err) => console.log(err));
  };
  const handleGetCity = (val) => {
    const payload = {
      state_id: val,
    };
    getCityAuthless(payload)
      .then((res) => {
        setCity(
          res.cities.map((i, index) => {
            return {
              label: i.name,
              value: i.id,
              key: i.id,
            };
          })
        );
      })
      .catch((err) => console.log(err));
  };
  const handleAutoFill = (e) => {
    form.getFieldValue(country);
    setcheck(e.target.checked);
    formRef.current.setFieldsValue({
      business_country: form.getFieldValue("country"),
      business_state: form.getFieldValue("state"),
      business_city: form.getFieldValue("city"),
      business_zip: form.getFieldValue("zip"),
    });
  };
  const handleFinish = (val) => {
    let data = { ...val };
    data.pan = data.pan.toUpperCase();
    data.dob = dayjs(data.dob).format("YYYY-MM-DD");
    const formdata = new FormData();
    formdata.append("is_same_address", check ? 1 : 0);
    formdata.append("aadhar_front", aadhar_frontbase);
    formdata.append("aadhar_back", aadhar_backbase);
    formdata.append("aadhar_front_back", aadhar_front_backbase);
    formdata.append("pan_card", pan_cardbase);
    formdata.append("business_proof", business_proofbase);
    formdata.append("retailer_photo", retailer_photobase);
    formdata.append("retailer_distributor", retailer_distributorbase);
    formdata.append("shop_photo", shop_photobase);
    formdata.append("bank_photo", bank_photobase);
    // formdata.append("member_type", selectedMember);
    // Object.entries(membercustomPayload).forEach(([key, val]) =>
    //   formdata.append(key, val)
    // );
    Object.entries(data).forEach(([key, val]) => formdata.append(key, val));
    userRegister(formdata)
      .then((res) => {
        if (res.status) {
          dispatchtoast(res.message);
          navigate("/");
        }
      })
      .catch((err) => console.log(err));
  };
  return (
    <>
      <div className=" bg-blue-100 h-screen ">
        <div className="grid grid-cols-1 md:grid-cols-2  w-full h-screen md:h-full  place-items-center">
          <div className="h-full      w-full px-10 flex flex-col justify-center items-center">
            <div className="flex justify-center items-center">
              <img
                src={Images.WebsiteLogo}
                className="w-1/4"
                style={{ filter: "drop-shadow(0px 1px 2px #cfc5c5)" }}
              />
            </div>
            <p className="text-black mt-10 font-[100] text-4xl">Welcome to</p>
            <p className="mt-6 text-3xl font-semibold text-black">
              {PortalName}
            </p>
            <p className="text-xs font-normal text-black mt-4">
              Bunch of all business solution logic
            </p>
            <img
              src={Images.loginillustration2}
              className="w-[300px] h-[200px] mt-4 m-4"
            />
          </div>
          <div className="bg-gray-100 p-8 h-screen  overflow-y-scroll shadow-lg shadow-gray-500 rounded-md">
            {/* <div className="flex justify-center items-center">
      <img
                src={Images.WebsiteLogo}
                style={{ filter: "drop-shadow(0px 1px 2px #cfc5c5)" }}
              />
      </div> */}
            <div className="p-4 ">
              <Form
                form={form}
                ref={formRef}
                name="basic"
                initialValues={{
                  remember: true,
                }}
                onFinishFailed={(err) => console.log(err)}
                onFinish={(val) => handleFinish(val)}
              >
                {/* <p className="text-md text-primary font-semibold">Add Member</p> */}
                <p className="mt-4 font-semibold">Member Details</p>
                <div className="grid xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-2 gap-x-5">
                  <Form.Item
                    name={`first_name`}
                    label={"First Name"}
                    labelCol={{ span: 24 }}
                    rules={[
                      {
                        required: true,
                        message: "Enter First Name!",
                      },
                      {
                        whitespace: true,
                        message: "Field cannot contain only blank spaces",
                      },
                    ]}
                  >
                    <CommonInput
                      placeholder="Enter Your First Name"
                      name="first_name"
                    />
                  </Form.Item>
                  <Form.Item
                    name={`last_name`}
                    label={"Last Name"}
                    labelCol={{ span: 24 }}
                    rules={[
                      {
                        required: true,
                        message: "Enter Last Name!",
                      },
                      {
                        whitespace: true,
                        message: "Field cannot contain only blank spaces",
                      },
                    ]}
                  >
                    <CommonInput
                      placeholder="Enter Your Last Name"
                      name="last_name"
                    />
                  </Form.Item>{" "}
                  <Form.Item
                    name={`dob`}
                    label={"Date Of Birth"}
                    labelCol={{ span: 24 }}
                    rules={[
                      {
                        required: true,
                        message: "Enter Date Of Birth!",
                      },
                    ]}
                  >
                    <DatePicker
                      popupClassName="bottom-calendar"
                      className="w-full datepicker h-9"
                      onChange={() => { }}
                      placement="bottomLeft"
                      placeholder="Enter DOB"
                    />
                  </Form.Item>
                  <Form.Item
                    labelCol={{ span: 24 }}
                    label="Gender"
                    name={"gender"}
                    rules={[
                      {
                        required: true,
                        message: "Select Gender!",
                      },
                    ]}
                  >
                    <div className="radio-group">
                      <Radio.Group name="gender">
                        <Radio value="0"> Male </Radio>
                        <Radio value="1"> Female </Radio>
                      </Radio.Group>
                    </div>
                  </Form.Item>
                  {/* <Form.Item
                    name={`aadhar`}
                    label={"Aadhar Number"}
                    labelCol={{ span: 24 }}
                    rules={[
                      {
                        required: true,
                        message: "Enter Aadhar Number!",
                      },
                      {
                        pattern: aadharPattern,
                        message: "Invalid Aadhaar card number format!",
                      },
                      {
                        whitespace: true,
                        message: "Field cannot contain only blank spaces",
                      },
                    ]}
                  >
                    <CommonInput
                      placeholder="Enter Your Aadhar Number"
                      name="aadhar"
                    />
                  </Form.Item>
                  <Form.Item
                    name={`pan`}
                    label={"Pan Card"}
                    labelCol={{ span: 24 }}
                    rules={[
                      {
                        required: true,
                        message: "Enter Pan!",
                      },
                      {
                        pattern: panPattern,
                        message: "Invalid PAN format!",
                      },
                      {
                        whitespace: true,
                        message: "Field cannot contain only blank spaces",
                      },
                    ]}
                  >
                    <CommonInput
                      placeholder="Enter Pan"
                      name="pan"
                    />
                  </Form.Item> */}
                  <Form.Item
                    name={`mobile`}
                    label={"Mobile"}
                    labelCol={{ span: 24 }}
                    rules={[
                      {
                        required: true,
                        message: "Enter your Mobile Number!",
                      },
                      {
                        pattern: mobilePattern,
                        message: "Invalid mobile number!",
                      },
                      {
                        whitespace: true,
                        message: "Field cannot contain only blank spaces",
                      },
                    ]}
                  >
                    <CommonInput
                      placeholder="Enter Your Mobile"
                      name="mobile"
                    />
                  </Form.Item>{" "}
                  <Form.Item
                    name={`email`}
                    label={"Email"}
                    labelCol={{ span: 24 }}
                    rules={[
                      {
                        required: true,
                        message: "Enter your Email!",
                      },
                      {
                        pattern: emailPattern,
                        message: "Enter Valid Email!",
                      },
                      {
                        whitespace: true,
                        message: "Field cannot contain only blank spaces",
                      },
                    ]}
                  >
                    <CommonInput
                      placeholder="Enter Your Email "
                      name="email"
                    />
                  </Form.Item>{" "}
                  <Form.Item
                    name={`address`}
                    label={"Address"}
                    labelCol={{ span: 24 }}
                    rules={[
                      {
                        required: true,
                        message: "Enter Address!",
                      },
                      {
                        whitespace: true,
                        message: "Field cannot contain only blank spaces",
                      },
                    ]}
                  >
                    <Input.TextArea
                      placeholder="Enter Your Address"
                      name="address"
                    />
                  </Form.Item>{" "}
                  <Form.Item
                    name={`country`}
                    label={"Country"}
                    labelCol={{ span: 24 }}
                    rules={[
                      {
                        required: true,
                        message: "Select  country!",
                      },
                    ]}
                  >
                    <Select
                      placeholder="--Select Country--"
                      showSearch
                      onChange={(val) => handleGetState(val)}
                      className="w-full mt-2"
                      options={country}
                    />
                  </Form.Item>{" "}
                  <Form.Item
                    name={`state`}
                    label={"State"}
                    labelCol={{ span: 24 }}
                    rules={[
                      {
                        required: true,
                        message: "Select state!",
                      },
                    ]}
                  >
                    <Select
                      placeholder="--Select State--"
                      showSearch
                      filterOption={filterOption}
                      onChange={(val) => handleGetCity(val)}
                      className="w-full mt-2"
                      options={state}
                    />
                  </Form.Item>
                  <Form.Item
                    name={`city`}
                    label={"City"}
                    labelCol={{ span: 24 }}
                    rules={[
                      {
                        required: true,
                        message: "Select city!",
                      },
                    ]}
                  >
                    <Select
                      placeholder="--Select City--"
                      showSearch
                      filterOption={filterOption}
                      className="w-full mt-2"
                      options={city}
                    />
                  </Form.Item>
                  <Form.Item
                    name={`zip`}
                    label={"Zip"}
                    labelCol={{ span: 24 }}
                    rules={[
                      {
                        required: true,
                        message: "Enter PinCode!",
                      },
                      {
                        pattern: pincodePattern,
                        message: "Invalid PIN code format!",
                      },
                      {
                        whitespace: true,
                        message: "Field cannot contain only blank spaces",
                      },
                    ]}
                  >
                    <CommonInput
                      placeholder="Enter Your Pin Code"
                      name="zip"
                    />
                  </Form.Item>
                </div>
                {/* <p className="mt-4 font-semibold">Business Details</p> */}
                {/* <div className="mt-2 flex justify-start items-center gap-x-3  checkbox">
                  <Input
                    checked={check}
                    onChange={(e) => handleAutoFill(e)}
                    className="rounded-md"
                    type="checkbox"
                  />
                  <p className="text-textGray">
                    Is business address is same as member address?
                  </p>
                </div> */}
                {/* <div className="grid xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-2 gap-x-5">
                  <Form.Item
                    name={`business_name`}
                    label={"Buisness Name"}
                    labelCol={{ span: 24 }}
                    rules={[
                      {
                        required: true,
                        message: "Enter Business Name!",
                      },
                      {
                        whitespace: true,
                        message: "Field cannot contain only blank spaces",
                      },
                    ]}
                  >
                    <CommonInput
                      placeholder="Enter Your Business Name"
                      name="business_name"
                    />
                  </Form.Item>
                  <Form.Item
                    name={`business_address`}
                    label={"Business Address"}
                    labelCol={{ span: 24 }}
                    rules={[
                      {
                        required: true,
                        message: "Enter Business Address!",
                      },
                      {
                        whitespace: true,
                        message: "Field cannot contain only blank spaces",
                      },
                    ]}
                  >
                    <CommonInput
                      placeholder="Enter Your Business Address"
                      name="business_address"
                    />
                  </Form.Item>{" "}
                  <Form.Item
                    name={`business_country`}
                    label={"Business Country"}
                    labelCol={{ span: 24 }}
                    rules={[
                      {
                        required: true,
                        message: "Select country!",
                      },
                    ]}
                  >
                    <Select
                      placeholder="--Select Country--"
                      showSearch
                      filterOption={filterOption}
                      allowClear
                      onChange={(val) => handleGetState(val)}
                      className="w-full h-9"
                      options={country}
                    />
                  </Form.Item>{" "}
                  <Form.Item
                    name={`business_state`}
                    label={"Business State"}
                    labelCol={{ span: 24 }}
                    rules={[
                      {
                        required: true,
                        message: "Select  state!",
                      },
                    ]}
                  >
                    <Select
                      placeholder="--Select State--"
                      showSearch
                      filterOption={filterOption}
                      onChange={(val) => handleGetCity(val)}
                      className="w-full h-9 "
                      options={state}
                    />
                  </Form.Item>{" "}
                  <Form.Item
                    name={`business_city`}
                    label={"Business City"}
                    labelCol={{ span: 24 }}
                    rules={[
                      {
                        required: true,
                        message: "Select any one city!",
                      },
                    ]}
                  >
                    <Select
                      placeholder="--Select City--"
                      showSearch
                      filterOption={filterOption}
                      className="w-full h-9"
                      options={city}
                    />
                  </Form.Item>{" "}
                  <Form.Item
                    name={`business_zip`}
                    label={"Business Zip "}
                    labelCol={{ span: 24 }}
                    rules={[
                      {
                        required: true,
                        message: "Enter Zip!",
                      },
                      {
                        pattern: pincodePattern,
                        message: "Invalid PIN code format!",
                      },
                      {
                        whitespace: true,
                        message: "Field cannot contain only blank spaces",
                      },
                    ]}
                  >
                    <CommonInput
                      placeholder="Business Zip Code"
                      name="business_zip"
                    />
                  </Form.Item>

           
                  <div className="w-full md:w-1/2 mt-4">
              <p>Member Detail</p>
             <Form.Item label={"Member Type "}
                labelCol={{ span: 24 }}
                rules={[
                  {
                    required: true,
                    message: "Select member type!",
                  },
                ]} name={"member_type"}
             >
             <Select
                placeholder="Select Member Type"
                showSearch
                filterOption={filterOption}
                className="w-full mt-2"
                options={memberType}
              />
             </Form.Item>
            </div>
                </div> */}
                {/* <div className="grid xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-2 gap-x-5 mt-4">
                    <Form.Item
                      name={`aadharfront`}
                      className="font-semibold"
                      label={"Aadhar Front Image"}
                      labelCol={{ span: 24 }}
                      rules={[
                        {
                          required: true,
                          message: "Upload Aadhar Front Image!",
                        },
                      ]}
                    >
                      <Upload
                        maxCount={1}
                        listType="picture"
                        customRequest={({ file }) => handleImageChange(file, "aadhar_front")}
                        fileList={aadhar_front}
                        accept=".jpg,.jpeg,.png,.webp"
                        {...props}
                        defaultFileList={[]}
                      >
                        <div className="flex justify-center  items-center gap-4 bg-green-600 text-white  px-4 py-2 shadow shadow-gray-400 rounded-3xl ">
                          <UploadOutlined />
                          <p>Upload</p>
                        </div>
                      </Upload>
                    </Form.Item>
                    <Form.Item
                      name={`aadharback`}
                      className="font-semibold"
                      label={"Aadhar Back Image"}
                      labelCol={{ span: 24 }}
                      rules={[
                        {
                          required: true,
                          message: "Upload Aadhar Back Image!",
                        },
                      ]}
                    >
                      <Upload
                        maxCount={1}
                        listType="picture"
                        customRequest={({ file }) => handleImageChange(file, "aadhar_back")}
                        fileList={aadhar_back}
                        accept=".jpg,.jpeg,.png,.webp"
                        {...props}
                        defaultFileList={[]}
                      >
                        <div className="flex justify-center  items-center gap-4 bg-green-600 text-white  px-4 py-2 shadow shadow-gray-400 rounded-3xl ">
                          <UploadOutlined />
                          <p>Upload</p>
                        </div>
                      </Upload>
                    </Form.Item>{" "}
                    <Form.Item
                      name={`aadharfrontback`}
                      className="font-semibold"
                      label={"Aadhar Front_back Image"}
                      labelCol={{ span: 24 }}
                      rules={[
                        {
                          required: true,
                          message: "Upload Aadhar Front_back Image!",
                        },
                      ]}
                    >
                      <Upload
                        maxCount={1}
                        listType="picture"
                        customRequest={({ file }) => handleImageChange(file, "aadhar_front_back")}
                        fileList={aadhar_front_back}
                        accept=".jpg,.jpeg,.png,.webp"
                        {...props}
                        defaultFileList={[]}
                      >
                        <div className="flex justify-center  items-center gap-4 bg-green-600 text-white  px-4 py-2 shadow shadow-gray-400 rounded-3xl ">
                          <UploadOutlined />
                          <p>Upload</p>
                        </div>
                      </Upload>
                    </Form.Item>{" "}
                    <Form.Item
                      name={`pancard`}
                      label={"Pan Card Image"}
                      className="font-semibold"
                      labelCol={{ span: 24 }}
                      rules={[
                        {
                          required: true,
                          message: "Upload Pan Card Image!",
                        },
                      ]}
                    >
                      <Upload
                        maxCount={1}
                        listType="picture"
                        customRequest={({ file }) => handleImageChange(file, "pan_card")}
                        fileList={pan_card}
                        accept=".jpg,.jpeg,.png,.webp"
                        {...props}
                        defaultFileList={[]}
                      >
                        <div className="flex justify-center  items-center gap-4 bg-green-600 text-white  px-4 py-2 shadow shadow-gray-400 rounded-3xl ">
                          <UploadOutlined />
                          <p>Upload</p>
                        </div>
                      </Upload>
                    </Form.Item>
                    <Form.Item
                      name={`businessproof`}
                      label={"Business Proof Image"}
                      className="font-semibold"
                      labelCol={{ span: 24 }}
                      rules={[
                        {
                          required: true,
                          message: "Upload Business Proof Image!",
                        },
                      ]}
                    >
                      <Upload
                        maxCount={1}
                        listType="picture"
                        customRequest={({ file }) => handleImageChange(file, "business_proof")}
                        fileList={business_proof}
                        accept=".jpg,.jpeg,.png,.webp"
                        {...props}
                        defaultFileList={[]}
                      >
                        <div className="flex justify-center  items-center gap-4 bg-green-600 text-white  px-4 py-2 shadow shadow-gray-400 rounded-3xl ">
                          <UploadOutlined />
                          <p>Upload</p>
                        </div>
                      </Upload>
                    </Form.Item>
                    <Form.Item
                      name={`retailerphoto`}
                      label={"Retailer Photo Image"}
                      className="font-semibold"
                      labelCol={{ span: 24 }}
                      rules={[
                        {
                          required: true,
                          message: "Upload Retailer Photo Image!",
                        },
                      ]}
                    >
                      <Upload
                        maxCount={1}
                        listType="picture"
                        customRequest={({ file }) => handleImageChange(file, "retailer_photo")}
                        fileList={retailer_photo}
                        accept=".jpg,.jpeg,.png,.webp"
                        {...props}
                        defaultFileList={[]}
                      >
                        <div className="flex justify-center  items-center gap-4 bg-green-600 text-white  px-4 py-2 shadow shadow-gray-400 rounded-3xl ">
                          <UploadOutlined />
                          <p>Upload</p>
                        </div>
                      </Upload>
                    </Form.Item>
                    <Form.Item
                      name={`retailerdistributor`}
                      label={"Retailer Distributor Image"}
                      className="font-semibold"
                      labelCol={{ span: 24 }}
                      rules={[
                        {
                          required: true,
                          message: "Upload Retailer Distributor Image!",
                        },
                      ]}
                    >
                      <Upload
                        maxCount={1}
                        listType="picture"
                        customRequest={({ file }) => handleImageChange(file, "retailer_distributor")}
                        fileList={retailer_distributor}
                        accept=".jpg,.jpeg,.png,.webp"
                        {...props}
                        defaultFileList={[]}
                      >
                        <div className="flex justify-center  items-center gap-4 bg-green-600 text-white  px-4 py-2 shadow shadow-gray-400 rounded-3xl ">
                          <UploadOutlined />
                          <p>Upload</p>
                        </div>
                      </Upload>
                    </Form.Item>
                    <Form.Item
                      name={`shopphoto`}
                      label={"Shop Photo Image"}
                      className="font-semibold"
                      labelCol={{ span: 24 }}
                      rules={[
                        {
                          required: true,
                          message: "Upload Shop Image!",
                        },
                      ]}
                    >
                      <Upload
                        maxCount={1}
                        listType="picture"
                        customRequest={({ file }) => handleImageChange(file, "shop_photo")}
                        fileList={shop_photo}
                        accept=".jpg,.jpeg,.png,.webp"
                        {...props}
                        defaultFileList={[]}
                      >
                        <div className="flex justify-center  items-center gap-4 bg-green-600 text-white  px-4 py-2 shadow shadow-gray-400 rounded-3xl ">
                          <UploadOutlined />
                          <p>Upload</p>
                        </div>
                      </Upload>
                    </Form.Item>
                    <Form.Item
                      name={`bankphoto`}
                      label={"Bank Image"}
                      className="font-semibold"
                      labelCol={{ span: 24 }}
                      rules={[
                        {
                          required: true,
                          message: "Upload Bank Image!",
                        },
                      ]}
                    >
                      <Upload
                        maxCount={1}
                        listType="picture"
                        customRequest={({ file }) => handleImageChange(file, "bank_photo")}
                        fileList={bank_photo}
                        accept=".jpg,.jpeg,.png,.webp"
                        {...props}
                        defaultFileList={[]}
                      >
                        <div className="flex justify-center  items-center gap-4 bg-green-600 text-white  px-4 py-2 shadow shadow-gray-400 rounded-3xl ">
                          <UploadOutlined />
                          <p>Upload</p>
                        </div>
                      </Upload>
                    </Form.Item>
                  </div> */}
                {/* <p className="mt-4 font-semibold">Package Details</p>
            <div className="w-full md:w-1/2 mt-4">
              <p>Member Detail</p>
              <Select
                placeholder="Select Member Type"
                showSearch
                filterOption={filterOption}
                onChange={(val) => handleGetPackage(val)}
                className="w-full mt-2"
                options={memberType}
              />
            </div>
            {packages.map((item, index) => {
              return (
                <div key={index} className="mt-4">
                  <p>{item.member_type}</p>
                  {index === 0 ? (
                    <div className="radio-group my-2">
                      <Radio.Group name={`type_${item.member_type_id}`}>
                        {item.packages.map((i, index) => (
                          <Radio
                            className="mt-2 md:mt-0"
                            onChange={(e) =>
                              setCustomMemberPayload({
                                ...membercustomPayload,
                                [e.target.name]: e.target.value,
                              })
                            }
                            name={`type_${item}`}
                            value={i.id}
                          >
                            {" "}
                            {i.package_name}{" "}
                          </Radio>
                        ))}
                      </Radio.Group>
                    </div>
                  ) : (
                    <div className="flex justify-start items-center md:gap-4 flex-wrap">
                      {item.packages.map((i, index) => {
                        return (
                          <div className="my-2 flex justify-start items-center gap-x-3  checkbox">
                            <Input
                              value={i.id}
                              name={
                                `type_${item.member_type_id}` + `[${index}]`
                              }
                              onChange={(e) => {
                                if (e.target.checked) {
                                  setCustomMemberPayload({
                                    ...membercustomPayload,
                                    [e.target.name]: e.target.value,
                                  });
                                } else {
                                  const check = Object.keys(
                                    membercustomPayload
                                  ).includes(e.target.name);
                                  let data = { ...membercustomPayload };
                                  check && delete data[e.target.name];
                                  setCustomMemberPayload(data);
                                }
                              }}
                              className="rounded-md"
                              type="checkbox"
                            />
                            <p className="text-textGray">{i.package_name}</p>
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
              );
            })} */}
                <div className="flex justify-start items-center mt-2">
                  <Form.Item>
                    <Button
                      htmlType="submit"
                      className="bg-primary outline-none border-none text-white"
                    >
                      Save
                    </Button>
                  </Form.Item>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>

    </>
  );
};

export default Register;
