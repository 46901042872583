import { Button, Modal, Select } from "antd";
import axios from "axios";
import React, { useState } from "react";
import {
  DeviceInfo,
  MantraIrisdeviceInfoAvdm,
  MantradeviceInfoAvdm,
  StartekDeviceInfo,
  captureUrl,
  convertXmlToJson,
  deviceOptions,
  dispatchtoast,
  parseXml,
} from "../../../../../Utils";
import {
  AepsKycBiometric,
  MemberProfile,
} from "../../../../../ApiServices/Apifun";
import { xml2json } from "../../../../../Utils/xml2json";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setParentProfile, setProfile } from "../../../../../Store/B2bslice";

const BioMatricModal = ({ isOpen, setIsOpen, refrence }) => {
  const [fields, setFields] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [btnLoading, setBtnLoading] = useState(false);
  const handleOk = () => {
    setIsOpen(false);
  };
  const handleCancel = () => {
    setIsOpen(false);
  };

  var PIDOPTS =
    '<PidOptions ver="1.0">' +
    '<Opts fCount="1" fType="2" iCount="" iType="" pCount="" pType="" format="0" pidVer="2.0" timeout="10000" env="P" otp="" wadh="E0jzJ/P8UopUHAieZn8CKqS4WPMi5ZSYXgfnlfkWjrc=" posh=""/>' +
    "</PidOptions>";
  var MANTRAPIDOPTS =
    '<?xml version="1.0"?> <PidOptions ver="1.0"> <Opts fCount="1" fType="2" iCount="0" pCount="0" format="0" pidVer="2.0" timeout="10000" wadh="E0jzJ/P8UopUHAieZn8CKqS4WPMi5ZSYXgfnlfkWjrc=" posh="UNKNOWN" env="P" /> </PidOptions>';
  const mantrairisPIDOPTS =
    '<?xml version="1.0"?> <PidOptions ver="1.0"> <Opts fCount="0" fType="0" iCount="1" pCount="0" pgCount="1" format="0"   pidVer="2.0" timeout="10000" pTimeout="20000" wadh="E0jzJ/P8UopUHAieZn8CKqS4WPMi5ZSYXgfnlfkWjrc=" posh="UNKNOWN" env="P" /> <CustOpts><Param name="mantrakey" value="" /></CustOpts> </PidOptions>';
    const startekPIDOPTS =
    '<?xml version="1.0"?> <PidOptions ver="1.0"> <Opts fCount="1" fType="2" iCount="0" pCount="0" format="0" pidVer="2.0" timeout="10000" wadh="E0jzJ/P8UopUHAieZn8CKqS4WPMi5ZSYXgfnlfkWjrc=" posh="UNKNOWN" env="P" /> </PidOptions>';

    const capture = (x,port=11100) => {
    setBtnLoading(true);
    try {
      axios({
        method: "CAPTURE", // Set the custom request method here
        url:
          fields === "morpho"
            ? `https://localhost:${port}/capture`
            : fields === "startek"
            ? "https://localhost:11200/rd/capture"
            : `https://127.0.0.1:${port}/rd/capture`,
        data:
          fields === "morpho"
            ? PIDOPTS
            : fields === "mantra"
            ? MANTRAPIDOPTS
            : fields === "startek"
            ? startekPIDOPTS
            : mantrairisPIDOPTS,
        headers: {
          "Content-Type": "text/xml",
          Accept: "text/xml",
        },
      })
        .then((response) => {
          const parsed = parseXml(response.data);
          const xmltojson = JSON.parse(
            xml2json(parsed).replace("undefined", "")
          );
          if (xmltojson.PidData.Resp.errCode !== "0") {
            dispatchtoast(
              `Failed to fetch fingerprint because of ${xmltojson.PidData.Resp.errInfo}`,
              true
            );
            setBtnLoading(false);
          } else {
            const parsed = parseXml(response.data);
            const xmltojson = JSON.parse(
              xml2json(parsed).replace("undefined", "")
            );
            const {
              errCode,
              errInfo,
              fCount,
              fType,
              pCount,
              iCount,
              nmPoints,
              qScore,
            } = xmltojson.PidData.Resp;
            const { dpId, rdsId, rdsVer, mi, mc, dc } =
              xmltojson.PidData.DeviceInfo;
            const { ci } = xmltojson.PidData.Skey;
            const { Hmac } = xmltojson.PidData;
            const payload = {
              device_imei: xmltojson?.PidData?.additional_info
              ? Array.isArray(xmltojson.PidData?.additional_info?.Param)
                ? xmltojson.PidData?.additional_info?.Param[0]?.value
                : xmltojson?.PidData?.additional_info?.Param.value
              : Array.isArray(
                  xmltojson?.PidData.DeviceInfo.additional_info?.Param
                )
              ? xmltojson?.PidData?.DeviceInfo?.additional_info?.Param[0]?.value
              : xmltojson?.PidData?.DeviceInfo?.additional_info?.Param?.value,
              primary_key_id: refrence.primaryKeyId,
              encode_fptxnid: refrence.encodeFPTxnId,
              errCode: errCode ? errCode : 0,
              errInfo: errInfo ? errInfo : "success",
              fCount: fCount ? fCount : 0,
              fType: fields === "morpho" || fields === "mantra" || fields === "startek" ? fType || 2 : 0,
            iType: fields === "morpho" || fields === "mantra"|| fields === "startek" ? 0 : 1,
              iCount: iCount ? iCount : 0,
              pCount: pCount ? pCount : 0,
              pType: 0,
              nmPoints: nmPoints ? nmPoints : 0,
              qScore: qScore ? qScore : 0,
              dpID: dpId ? dpId : 0,
              rdsID: rdsId ? rdsId : 0,
              rdsVer: rdsVer ? rdsVer : 0,
              dc: dc ? dc : 0,
              mi: mi ? mi : 0,
              mc: mc ? mc : 0,
              ci: ci ? ci : 0,
              sessionKey: xmltojson.PidData.Skey["#text"],
              hmac: Hmac ? Hmac : 0,
              PidDatatype: xmltojson.PidData.Data.type,
              Piddata: xmltojson.PidData.Data["#text"],
            };
            const formdata = new FormData();
            Object.entries(payload).forEach(([key, val]) =>
              formdata.append(key, val)
            );
            AepsKycBiometric(formdata)
              .then((res) => {
                if (res.status) {
                  setBtnLoading(false);
                  navigate(
                    location.state?.from
                      ? location.state?.from
                      : "/banking/aeps-cash-withdrawl",
                    { state: { allow: true } }
                  );
                  MemberProfile()
                    .then((res) => {
                      if (res.status) {
                        dispatch(setProfile(res.data.profile));
                        dispatch(setParentProfile(res.data.parent_detail));
                      } else {
                        dispatchtoast("Unable to fetch profile detail", true);
                      }
                    })
                    .catch((err) => console.log(err, "errr"));
                }
              })
              .catch((err) => {
                setBtnLoading(false);
                console.log(err);
              });
          }
        })
        .catch((error) => {
          setBtnLoading(false);
        });
    } catch (error) {
      dispatchtoast("Something went wrong", true);
      console.log(error);
    }
  };

  const handleSubmit = () => {
    if (fields === "morpho") {
      DeviceInfo((imei,index) => capture(imei,index));
    } else if (fields === "mantra") {
      MantradeviceInfoAvdm((x,port) => capture(x,port));
    } else if (fields === "iris") {
      MantraIrisdeviceInfoAvdm(() => capture());
    } else if (fields === "startek") {
      StartekDeviceInfo(() => capture());
    }
  };
  return (
    <>
      <Modal
        footer={null}
        title="Biometric"
        open={isOpen}
        width={400}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <div className="w-full  flex justify-center items-start mt-5 flex-col">
          <p className={`required text-textGray mt-2`}>Select Device</p>
          <Select
            value={fields}
            placeholder="Select a device"
            onChange={(val) => setFields(val)}
            className="w-full mt-2"
            options={deviceOptions}
          />
          <div className="flex w-full justify-center items-center">
            <Button
              onClick={handleSubmit}
              loading={btnLoading}
              className="bg-primary p-3 w-full flex justify-center items-center outline-none border-none  text-white  rounded-full mt-4"
            >
              Submit Kyc{" "}
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default BioMatricModal;
