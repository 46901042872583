import React, { useEffect, useRef, useState } from "react";

const MarqueeText = ({ text }) => {
  const textRef = useRef(null);
  const [duration, setDuration] = useState(20); // default fallback

  useEffect(() => {
    const textWidth = textRef.current?.offsetWidth || 0;
    const containerWidth = textRef.current?.parentElement.offsetWidth || 0;

    // Adjust speed: e.g., 100px = 1s
    const newDuration = (textWidth + containerWidth) / 100;
    setDuration(newDuration);
  }, [text]);

  return (
    <div className="overflow-hidden w-full">
      <p
        ref={textRef}
        className="inline-block whitespace-nowrap animate-marquee font-bold text-2xl text-white mt-8"
        style={{
          animationDuration: `${duration}s`,
        }}
      >
        {text}
      </p>
    </div>
  );
};

export default MarqueeText;
