import {
    Button,
    DatePicker,
    Modal,
    Select,
    Skeleton,
    Spin,
    Table,
    Tag,
    Tooltip,
} from "antd";
import React, { useEffect, useState } from "react";
import { trimString } from "../../../Utils";
import { getAddFunReport, getFunReqestReport } from "../../../ApiServices/Apifun";
import dayjs from "dayjs";
import ReportDrawer from "../../../Common/ReportDrawer";
import { AiOutlineSearch } from "react-icons/ai";
import Content from "../../../Common/ReceiptContent";
import { setselectedTab } from "../../../Store/B2bslice";
import { useDispatch } from "react-redux";
import CommonInput from "../../../Common/CommonInput";
import CommonPagination from "../../../Common/Pagination";

const AddFundReport = () => {
    const dispatch = useDispatch();
    const [dataSource, setDataSource] = useState([]);
    const [current, setCurrent] = useState(1);
    const [searchKeyword, setSearchKeyWord] = useState("");
    const [searchcolumn, setSearchColumn] = useState(null);
    const [fromDate, setFromDate] = useState("");
    const [toDate, setToDate] = useState("");
    const [openImageModal, SetOpenImageModal] = useState(false);
    const [attachment, setAttachment] = useState(null);
    const [totalPages, setTotalPages] = useState(null);
    const [pageSize, setPageSize] = useState(10);
    const [openReceiptDrawer, setOpenReceiptDrawer] = useState(false);
    const [result, setResult] = useState({ data: null, transaction_id: null });
    const [searchOptions, setSearchOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const ReportColumn = [
        {
            title: "Transaction Date",
            dataIndex: "transaction_date",
            key: "transaction_date",
            render: (text) => <p className="font-medium w-[120px]">{text}</p>,
        },
        {
            title: "Transaction Complete Date",
            dataIndex: "txn_completion_date",
            key: "txn_completion_date",
            render: (text) => <p className="font-medium w-[120px]">{text ?? "-"}</p>,
        },

        {
            title: "Customer Name",
            dataIndex: "customer_name",
            key: "customer_name",
            render: (text) => {
                return (
                    <>
                        <p className="w-[100px] font-medium  overflow-y-scroll">{text ?? "-"}</p>
                    </>
                );
            },
        },
        {
            title: "Customer mobile",
            dataIndex: "customer_phone",
            key: "customer_phone",
            render: (text) => {
                return (
                    <>
                        <p className="w-[100px] font-medium whitespace-nowrap overflow-y-scroll">{text ?? "-"}</p>
                    </>
                );
            },
        },

        {
            title: "Transaction Id",
            dataIndex: "transaction_id",
            key: "transaction_id",
            render: (text) => {
                return (
                    <>
                        <p className="w-[100px] whitespace-nowrap font-medium">{text}</p>
                    </>
                );
            },
        },
        {
            title: "Amount",
            dataIndex: "amount",
            key: "amount",
            render: (text) => (
                <p style={{ width: "80px" }} className=" font-medium">
                    {text}
                </p>
            ),
        },

        {
            title: "Status",
            dataIndex: "status",
            key: "status",
            render: (text) => {
                return (
                    <>
                        {text === "1" ? (
                            <Tag color="green">Approved</Tag>
                        ) : text === "2" ? (
                            <Tag color="red">Rejected</Tag>
                        ) : text === "0" ? (
                            <Tag color="red">Inactive</Tag>
                        ) : (
                            <Tag color="yellow">Pending</Tag>
                        )}
                    </>
                );
            },
        },
        {
            title: "Amount",
            dataIndex: "amount",
            key: "amount",
            render: (text) => (
                <p style={{ width: "120px" }} className=" font-medium">
                    {text} Rs.
                </p>
            ),
        },

        {
            title: "Remarks",
            dataIndex: "remark",
            key: "remark",
            render: (text) => {
                return (
                    <>
                        <Tooltip title={text}>
                            <p className="font-medium w-[150px] overflow-y-scroll">
                                {text ? trimString(text) : "-"}
                            </p>
                        </Tooltip>
                    </>
                );
            },
        },

    ];

    const getReport = (
        search_keyword = searchKeyword,
        search_column = searchcolumn,
        from_date = fromDate,
        to_date = toDate
    ) => {
        setLoading(true);
        const payload = {
            per_page: pageSize,
            page_no: current,
            search_keyword: search_keyword,
            search_column: search_column,
            from_date: from_date,
            to_date: to_date,
        };
        const formdata = new FormData();
        Object.keys(payload).map((key) => formdata.append(key, payload[key]));
        getAddFunReport(formdata)
            .then((res) => {
                setDataSource(res.data);
                setTotalPages(res.total);
                setSearchOptions(
                    res?.filter_column_name?.map((i, index) => {
                        return {
                            label: i,
                            value: res.filter_column_key[index],
                        };
                    })
                );
            })
            .catch((err) => console.log(err))
            .finally(() => setLoading(false));
    };
    useEffect(() => {
        getReport();
    }, [current, pageSize]);
    useEffect(() => {
        dispatch(setselectedTab("11.890"));
    }, []);
    return (
        <>
            <div className="">
                <div className="prepaid  animate-fade-in w-full  bg-white shadow-normal  rounded-xl p-4">
                    <div className="flex justify-between items-center">
                        <p className="font-bold text-[15px] mb-2">Fund Request Report</p>
                        <p
                            onClick={() => {
                                getReport("", "", "", "");
                                setSearchKeyWord("");
                                setSearchColumn(null);
                                setFromDate("");
                                setToDate("");
                            }}
                            className="font-medium text-xs cursor-pointer bg-bsred hover:bg-red-600 text-white px-2 py-1 rounded-md text-[15px] mb-2"
                        >
                            Clear Filter
                        </p>
                    </div>
                    <div className="selectionFields grid grid-cols-1 md:grid-cols-3 gap-4 place-items-center ">
                        <div className="w-full">
                            <p className={`required text-textGray mt-2`}>
                                Select Search Type
                            </p>
                            <Select
                                placeholder="Select search type"
                                value={searchcolumn}
                                onChange={(val) => setSearchColumn(val)}
                                className="w-full mt-2"
                                options={searchOptions}
                            />
                        </div>
                        <div className="w-full">
                            <p className={`required text-textGray `}>Keyword</p>
                            {searchcolumn === "fund_requests.created_at" ? (
                                <DatePicker
                                    // value={dayjs("2023-09-07","YYYY-MM-DD")}
                                    popupClassName="bottom-calendar"
                                    allowClear
                                    // value={searchKeyword}
                                    className="w-full datepicker mt-4"
                                    onChange={(val) =>
                                        setSearchKeyWord(dayjs(val).format("YYYY-MM-DD"))
                                    }
                                    placement="bottomLeft"
                                    placeholder="Search"
                                />
                            ) : (
                                <CommonInput
                                    placeholder="Seacrh"
                                    value={searchKeyword}
                                    onChange={(e) => setSearchKeyWord(e.target.value)}
                                    className="outline-none text-xs border mt-4 w-full  border-gray-200 focus:border-primary   px-2 "
                                />
                            )}
                        </div>
                        <div className="w-full">
                            <p className={`required text-textGray mt-2`}>
                                From Date - To Date
                            </p>
                            <DatePicker.RangePicker
                                allowClear
                                onChange={(val) => {
                                    setFromDate(val ? dayjs(val[0]).format("YYYY-MM-DD") : "");
                                    setToDate(val ? dayjs(val[1]).format("YYYY-MM-DD") : "");
                                }}
                                className="w-full mt-2"
                            />
                        </div>{" "}
                    </div>
                    <div className="w-full flex justify-end items-center mt-3">
                        <Button
                            // disabled=
                            onClick={() => getReport()}
                            style={{ color: "white" }}
                            className=" font-semibold flex justify-between gap-2 items-center hover:bg-primaryhover bg-primary border-none"
                        >
                            <AiOutlineSearch />
                            <p>Search</p>
                        </Button>
                    </div>
                </div>
            </div>
            <div>
                <div className="mt-4">
                    <Spin spinning={loading}>
                        <Table
                            className="history-table animate-fade-in whitespace-nowrap  max-h-[800px]  overflow-x-auto text-white rounded-lg"
                            columns={ReportColumn}
                            pagination={false}
                            onRow={(record, rowIndex) => {
                                return {
                                    onClick: (event) => {
                                        setResult({
                                            data: {
                                                Date: record.date,
                                                "Member Id": record.member_id,
                                                "From Bank": record.from_bank,
                                                "To Bank": record.to_bank,
                                                "Payment Mode": record.payment_mode,
                                                Amount: record.amount,
                                                "Admin Remark": record.admin_remark,
                                                Remark: record.remark,
                                            },
                                            transaction_id: record.transaction_id,
                                        });
                                        setOpenReceiptDrawer(true);
                                    },
                                    className: "cursor-pointer text-[12px]"
                                };
                            }}
                            dataSource={dataSource}
                        />
                        <div className="mt-2 mb-8 flex justify-end items-center">
                            <CommonPagination totalPages={totalPages} current={current} pageSize={pageSize} setCurrent={setCurrent} setPageSize={setPageSize} />
                        </div>
                    </Spin>
                </div>
            </div>
            {/* {openReceiptDrawer && (
          <ReportDrawer
            title={"Fund Request Receipt"}
            open={openReceiptDrawer}
            setOpen={setOpenReceiptDrawer}
            content={<Content data={result} />}
          />
        )}
        {openImageModal && (
          <Modal
            footer={null}
            open={openImageModal}
            onCancel={() => SetOpenImageModal(false)}
            title="Attachment"
          >
            <img className="h-[400px] w-full" src={attachment} />
          </Modal>
        )} */}
        </>
    );
};

export default AddFundReport;
