import { AutoComplete, Button, Form, Select, Spin } from "antd";
import React, { useEffect, useRef, useState } from "react";
import CommonInput from "../../../Common/CommonInput";
import {
  dispatchtoast,
  filterOption,
  getLocalStorageItem,
} from "../../../Utils";
import {
  XpressPayoutOtp,
  getDmtBanks,
  getdmtBeneficiary,
  verifyBankAccount,
} from "../../../ApiServices/Apifun";
import OtpPayout from "./OtpPayout";
import ReportDrawer from "../../../Common/ReportDrawer";
import Content from "../../../Common/ReceiptContent";
import { useDispatch, useSelector } from "react-redux";
import { setselectedTab } from "../../../Store/B2bslice";
import { AiFillCheckCircle } from "react-icons/ai";
import { useNavigate } from "react-router-dom";

const DmtVerification = () => {
  const [banks, setBanks] = useState([]);
  const [verifyBtnLoading, setVerifyBtnLoading] = useState(false)
  const [selectLoading, setselectLoading] = useState(false);
  const [details, setDetails] = useState([]);
  const [result, setResult] = useState({ data: null, transaction_id: null });
  const [openReceiptDrawer, setOpenReceiptDrawer] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const [openOtpModal, setOpenOtpModal] = useState(false);
  const [allData, setAllData] = useState({});
  const [btnLoading, setBtnLoading] = useState(false);
  // const [needToVerify,setNeedtoVerify]=useState(true)
  const formref = useRef();
  const [form] = Form.useForm();
  const navigate = useNavigate()
  const memberServices = useSelector((state) => state.B2B.memberServices);
  const dispatch = useDispatch();
  const getAllBanks = () => {
    setselectLoading(true);
    getDmtBanks()
      .then((res) => {
        if (res.status) {
          setBanks(
            res.data.banks.map((i, index) => {
              return {
                label: i.bank_name,
                value: i.bank_id,
                key: i?.ifsc_code ? i.ifsc_code : "",
              };
            })
          );
          setselectLoading(false);
        } else {
          setBanks([]);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setselectLoading(false));
  };
  let debounceTimer;
  useEffect(() => {
    dispatch(setselectedTab("5.89"));
    if (!memberServices.includes("101")) {
      navigate("/not-Available");
    } else {
      getAllBanks();
    }
  }, []);
  const handleFill = (val) => {
    form.setFieldsValue({
      benificiary_account: val.account_no,
      benificiary_ifsc: val.ifsc_code,
      bank_id: val.bank_id,
      benificiary_name: val.account_holder,
    });
  };
  const getBeneFiciarySearch = (e) => {
    setSpinner(true);
    const formdata = new FormData();
    formdata.append("search_keyword", e);
    clearTimeout(debounceTimer);

    // Set a new debounce timer
    debounceTimer = setTimeout(() => {
      // Trigger the search function after the debounce delay (e.g., 300 milliseconds)
      getdmtBeneficiary(formdata)
        .then((res) => {
          // if (res.data.banks.length !== 0) {
          setDetails(
            res.data.banks.map((i, index) => {
              return {
                label: (
                  <div
                    onClick={() => handleFill(i)}
                    className="grid text-black font-semibold grid-cols-1 md:grid-cols-2 place-items-center"
                  >
                    <p>{i.account_holder}</p>
                    <p>{i.account_no}</p>
                  </div>
                ),
                value: i.account_no,
              };
            })
          );
          // }
          // setDetails([
          //   ...res.data.banks.map((i) => {
          //     return { ...i, selected: false };
          //   }),
          // ]);
          console.log(res);
        })
        .catch((err) => console.log(err))
        .finally(() => setSpinner(false));
    }, 300);
  };

  const handleXpressPayoutOtpCall = (val) => {
    setAllData({ ...val });
    setOpenOtpModal(true);
  }
  const handlePay = (val) => {
    setOpenOtpModal(true);
    handleXpressPayoutOtpCall(val)
  };
  const handleVerify = () => {
    setVerifyBtnLoading(true)
    const val = form.getFieldsValue()
    const formdata = new FormData();
    const data = { ...val };
    delete data["amount"];
    data.bank_id = data.bank_id?.value ? data.bank_id?.value : data?.bank_id
    formdata.append("latitude", getLocalStorageItem("latitude"));
    formdata.append("longitude", getLocalStorageItem("longitude"));
    Object.keys(data).map((key) => formdata.append(key, data[key]));
    verifyBankAccount(formdata)
      .then((res) => {
        if (res.status) {
          dispatchtoast("Bank verified successfully!");
          formref.current.setFieldsValue({
            benificiary_name: res.data.beneficiary_name
          })
        } else {
          setVerifyBtnLoading(false);
        }
      })
      .catch((err) => {
        setVerifyBtnLoading(false);
      }).finally(() => {
        setVerifyBtnLoading(false)
      })
  }
  const handlefillIFSC = (val) => {
    formref.current.setFieldsValue({
      benificiary_ifsc: val.key
    })
  }
  return (
    <>
      <div className="grid grid-cols-1 md:grid-cols-1 place-items-start">
        <div className="prepaid animate-slide-in w-full md:w-1/2 bg-white shadow-normal  rounded-3xl p-5">
          <p className="font-bold text-[15px] mb-2">DMT Payout</p>
          <Form
            name="basic"
            initialValues={{
              remember: true,
            }}
            ref={formref}
            form={form}
            onFinishFailed={(err) => console.log(err)}
            onFinish={(val) => handlePay(val)}
          >
            <Form.Item
              name={`benificiary_account`}
              label={"Account Number"}
              labelCol={{ span: 24 }}
              className="w-full"
              rules={[
                {
                  required: true,
                  message: "Enter Account Number!",
                },
                {
                  whitespace: true,
                  message: "Field cannot contain only blank spaces",
                },
              ]}
            >
              <AutoComplete
                className="w-full"
                value={""}
                onSearch={(e) => getBeneFiciarySearch(e)}
                placeholder="Account Number"
                options={details}
              />
            </Form.Item>
            <Spin spinning={spinner}>
              <Form.Item
                name={`bank_id`}
                label={"Select Bank"}
                labelCol={{ span: 24 }}
                rules={[
                  {
                    required: true,
                    message: "Select One Bank!",
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Select a bank"
                  filterOption={filterOption}
                  className="w-full mt-2"
                  labelInValue
                  onChange={(val) => handlefillIFSC(val)}
                  loading={selectLoading}
                  options={banks}
                />
              </Form.Item>
              <Form.Item
                name={`benificiary_ifsc`}
                label={"IFSC"}
                labelCol={{ span: 24 }}
                rules={[
                  {
                    required: true,
                    message: "Enter IFSC!",
                  },
                  {
                    whitespace: true,
                    message: "Field cannot contain only blank spaces",
                  },
                ]}
              >
                <CommonInput onInput={(e) => {
                  const value = e.target.value.toUpperCase();
                  e.target.value = value;
                }} placeholder="Enter IFSC" name="benificiary_ifsc" />
              </Form.Item>{" "}
              <div className="flex justify-end items-center w-full">
                <Button
                  onClick={handleVerify}
                  loading={verifyBtnLoading}
                  className="w-1/2 lg:w-1/4 bg-primary text-white border-none"
                >Verify Bank</Button>
              </div>
              <Form.Item
                name={`amount`}
                label={"Amount"}
                labelCol={{ span: 24 }}
                rules={[
                  {
                    required: true,
                    message: "Enter Amount!",
                  },
                  {
                    whitespace: true,
                    message: "Field cannot contain only blank spaces",
                  },
                ]}
              >
                <CommonInput
                  onInput={(e) => {
                    const value = e.target.value.replace(/\D/g, "");
                    e.target.value = value;
                  }}
                  placeholder="Enter Amount"
                  name="amount"
                />
              </Form.Item>
              <Form.Item
                name={`benificiary_name`}
                label={"Benificiary Name"}
                labelCol={{ span: 24 }}
                rules={[
                  {
                    required: true,
                    message: "Enter Benificiary Name!",
                  },
                  {
                    whitespace: true,
                    message: "Field cannot contain only blank spaces",
                  },
                ]}
              >
                <CommonInput
                  placeholder="Enter Benificiary Name"
                  name="benificiary_name"
                />
              </Form.Item>{" "}

              {/* )} */}
            </Spin>
            <Form.Item>
              <Button
                loading={btnLoading}
                className="w-full bg-primary border-none mt-4"
                style={{ color: "white" }}
                htmlType="submit"
              >
                Pay
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
      {openOtpModal && (
        <OtpPayout
          setResult={setResult}
          setOpenReceiptDrawer={setOpenReceiptDrawer}
          isOpen={openOtpModal}
          form={form}
          setIsOpen={setOpenOtpModal}
          otherFields={allData}
        />
      )}
      {openReceiptDrawer && (
        <ReportDrawer
          title={"Express Payout Receipt"}
          open={openReceiptDrawer}
          setOpen={setOpenReceiptDrawer}
          content={<Content data={result} />}
        />
      )}
    </>
  );
};

export default DmtVerification;
